@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap'); // Importa a fonte

@import '../node_modules/bootstrap/scss/functions';
@import '../node_modules/bootstrap/scss/variables';
@import '../node_modules/bootstrap/scss/mixins';

$theme-colors: (
    "primary": #034aad,
    "secondary": #dddddd,
    "success": #22bb33,
    "warning": #ff5300,
    "danger": #bb2124,
    "dark": #363a3f,
    "strong_blue": #020066
);


// Trocar fonte para Raleway
$body-font-family: 'Raleway', sans-serif;

@import "~bootstrap/scss/bootstrap";


.w-100 {
    width: 100% !important;
}

.w-75 {
    width: 75% !important;
}

.w-50 {
    width: 50% !important;
}

.w-25 {
    width: 25% !important;
}

.w-auto {
    width: auto !important;
}

@media (min-width: 576px) {
    .w-sm-100 {
        width: 100% !important;
    }

    .w-sm-75 {
        width: 75% !important;
    }

    .w-sm-50 {
        width: 50% !important;
    }

    .w-sm-25 {
        width: 25% !important;
    }

    .w-sm-auto {
        width: auto !important;
    }
}

@media (min-width: 768px) {
    .w-md-100 {
        width: 100% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-25 {
        width: 25% !important;
    }

    .w-md-auto {
        width: auto !important;
    }
}

@media (min-width: 992px) {
    .w-lg-100 {
        width: 100% !important;
    }

    .w-lg-75 {
        width: 75% !important;
    }

    .w-lg-50 {
        width: 50% !important;
    }

    .w-lg-25 {
        width: 25% !important;
    }

    .w-lg-auto {
        width: auto !important;
    }
}

@media (min-width: 1200px) {
    .w-xl-100 {
        width: 100% !important;
    }

    .w-xl-75 {
        width: 75% !important;
    }

    .w-xl-50 {
        width: 50% !important;
    }

    .w-xl-25 {
        width: 25% !important;
    }

    .w-xl-auto {
        width: auto !important;
    }
}

@media (min-width: 1400px) {
    .w-xxl-100 {
        width: 100% !important;
    }

    .w-xxl-75 {
        width: 75% !important;
    }

    .w-xxl-50 {
        width: 50% !important;
    }

    .w-xxl-25 {
        width: 25% !important;
    }

    .w-xxl-auto {
        width: auto !important;
    }
}